import React from "react";
import styles from "./TenantInformation.module.scss";
import Icon from "../../../components/icons/Icon/Icon";
import {ITenant} from "../../../store/portal/portalTypes";

interface TenantInformationProps {
  icon: string;
  tenant: ITenant;
}

const TenantInformation = ({icon, tenant}: TenantInformationProps) => {

  return (
    <div className={styles.component}>
      <Icon className={styles.tenantIcon} src={icon}/>
      <div className={styles.message}>
        <div>{tenant.name}</div>
        <div>{tenant.company.address?.street}, {tenant.company.address?.number}, {tenant.company.address?.postal_code}, {tenant.company.address?.city}, {tenant.company.address?.country}</div>
        <div>{`${tenant.company?.email},  ${tenant.company?.phone_number}, ${tenant.company?.vat_number}`}</div>
      </div>
    </div>
  );
}

export default TenantInformation;