import axios, {AxiosError, AxiosRequestConfig, AxiosResponse, Method} from 'axios';
import {Token} from "client-oauth2";
import Authentication from "./authentication";

export const requestConfig = (client?: "authenticated" | "registration" | "userless" | "passwordRecover"): Promise<AxiosRequestConfig> => {
  /**
   * Generate the authentication headers required to communicate with the API
   */

  if (client === undefined) return Authentication.auth().then((token: Token) => ({
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
      }
    })
  );

  return Authentication.auth(client).then((token: Token) => ({
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
      }
    })
  );
}

const Fetcher = async (endpoint: string, method: Method, body?: object, params?: object, headers?: AxiosRequestConfig): Promise<AxiosResponse> => {
  axios.interceptors.request.use(
    function (config) {
      return config;
    },
    function (error) {
      return Promise.reject(error);
    })

  axios.interceptors.response.use(
    async (res: AxiosResponse) => {
      const response = {...res};
      if (response?.data?.next) {
        const urlParams = new URLSearchParams(response.data.next);
        response.data.offset = parseInt(urlParams.get('offset') || '', 10);
      }

      return response;
    },
    async (error) => {
      const status = error.response ? error.response.status : null;
      // if ([403, 500].includes(status)) {
      //   localStorage.clear();
      //   return Authentication.logout();
      // }

      // Refresh token if it's not an email verification error
      if (
        status === 401
        && !error.response.config.url.endsWith('/verify/')
        && !error.response.config.url.endsWith('/password/')
        && !error.response.config.url.endsWith('/change_password/')
      ) {
        return Authentication.refreshToken()
          .then((token: Token) => {
            error.config.headers['Authorization'] = 'Bearer ' + token.accessToken;
            error.config.baseURL = undefined;
            return axios.request(error.config);
          })
          .catch((error: AxiosError) => {
            console.debug(error);
          });
      }

      return Promise.reject(error);
    });


  let config = await requestConfig();

  return axios(endpoint, {
    method: method,
    headers: headers?.headers || config.headers, ...(body && {data: body}),
    params
  })
    .then(async (response) => {
      return response
    })
    .catch(async (error) => Promise.reject(error));
}

export default Fetcher;
