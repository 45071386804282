import { APIStatus } from "utils/apiStatus";

export interface PaymentState {
  methods?: PaymentMethodResponse;
  session?: PaymentSessionResponse;
  contact?: PaymentContact;
  result?: AdyenResult;
	verification?: {
		method: string;
		reference: string;
		status: string;
		error?: string;
		expiryTimestamp: string;
	};
}

export interface AdyenPaymentState {
  data: AdyenPaymentData;
  isValid: boolean;
}

export interface AdyenPaymentData {
  clientStateDataIndicator: boolean;
  paymentMethod: {
    type: string,
    issuer: string
  };
  riskData: {
    clientData: string
  },
  billingAddress?: {
    city: string;
    country: string;
    houseNumberOrName: string;
    postalCode: string;
    stateOrProvince: string;
    street: string;
  },
  browserInfo?: {
    acceptHeader: string;
    colorDepth: number;
    javaEnabled: boolean;
    language: string;
    screenHeight: number;
    screenWidth: number;
    timeZoneOffset: number;
    userAgent: string;
  }
}

export interface Payment {
  merchantReference: string;
  pspReference: string;
  action?: {
    type: string,
    paymentMethodType: string,
    method: string,
    url: string
  };
  resultCode: string;
}

export interface AdyenResult {
  resultCode: string;
  sessionData: string;
}

export interface PaymentDetailsRequest {
  details: {
    additionalProp1: string,
    additionalProp2: string,
    additionalProp3: string
  },
  paymentData: string,
  three_D_SAuthenticationOnly: boolean
}

interface CurrencyData {
  currency: string;
  value: number;
}

export interface PaymentDetailsResponse {
  additionalData: object;
  amount: CurrencyData;
  donationToken: string;
  fraudResult: {
    accountScore: number;
    results: {
      accountScore: number;
      checkId: number;
      name: string;
    }[];
  }
  merchantReference: string;
  pspReference: string;
  order: {
    amount: CurrencyData;
    expiresAt: string;
    orderData: string;
    pspReference: string;
    reference: string;
    remainingAmount: CurrencyData
  }
  threeDS2ResponseData: {
    acsChallengeMandated: string;
    acsOperatorID: string;
    acsReferenceNumber: string;
    acsSignedContent: string;
    acsTransID: string;
    acsURL: string;
    authenticationType: string;
    cardHolderInfo: string;
    cavvAlgorithm: string;
    challengeIndicator: string;
    dsReferenceNumber: string;
    dsTransID: string;
    exemptionIndicator: string;
    messageVersion: string;
    riskScore: string;
    sdkEphemPubKey: string;
    threeDSServerTransID: string;
    transStatus: string;
    transStatusReason: string;
  }
  threeDS2Result: {
    authenticationValue: string;
    cavvAlgorithm: string;
    challengeCancel: string;
    challengeIndicator: 'noPreference' | 'requestNoChallenge' | 'requestChallenge' | 'requestChallengeAsMandate'
    dsTrandID: string;
    eci: string;
    exceptionIndicator: 'lowValue' | 'secureCorporate' | 'trustedBeneficiary' | 'transactionRiskAnalysis';
    messageVersion: string;
    riskScore: string;
    threeDSServerTransID: string;
    timestamp: string;
    transStatus: string;
    transStatusReason: string;
    whiteListStatus: string;
  }
  resultCode: PaymentResultCode;
  refusalReason: string;
  refusalReasonCode: string;
  shopperLocale: string;
  threeDSPaymentData: string;
}

export enum PaymentResultCode {
  AuthenticationFinished = 'AuthenticationFinished',
  AuthenticationNotRequired = 'AuthenticationNotRequired',
  Authorised = 'Authorised',
  Cancelled = 'Cancelled',
  ChallengeShopper = 'ChallengeShopper',
  Error = 'Error',
  IdentifyShopper = 'IdentifyShopper',
  Pending = 'Pending',
  PresentToShopper = 'PresentToShopper',
  Received = 'Received',
  RedirectShopper = 'RedirectShopper',
  Refused = 'Refused'
}

export interface PaymentMethodResponse {
  groups: Array<PaymentGroup>,
  paymentMethods: Array<PaymentMethod>,
  allowedMethods: Array<string>
}

export interface InvoiceResponse {
  orders: [
    {
      price: number;
      tax: {
        vat: number;
        sales: number
      };
      hash: string;
      assignment: number
    }
  ];
  contact: {
    email: string;
    first_name: string;
    last_name: string;
    phone_number: string;
    user: number
  };
  currency: {
    name: string;
    code: string;
    symbol: string;
    exponent: number
  };
  price: {
    net: number;
    gross: number
  };
  status: APIStatus
}

export interface PaymentContactResponse {
  email: string;
  first_name: string;
  last_name: string;
  phone_number?: string;
}

export interface PaymentSessionResponse {
  amount?: {
    currency: string;
    value: number;
  }
  countryCode?: string;
  expiresAt?: string;
  id: string;
  merchantAccount?: string;
  reference?: string;
  returnUrl?: string;
  sessionData?: string;
}

export interface PaymentGroup {
  groupType?: string,
  name?: string,
  types?: string[]
}

export interface PaymentMethod {
  brands?: string[],
  configuration?: {
    additionalProp1: string,
    additionalProp2: string,
    additionalProp3: string
  },
  details?: [
    {
      configuration: {
        additionalProp1: string,
        additionalProp2: string,
        additionalProp3: string
      },
      items: {
        id: string,
        name: string
      }[],
      key: string,
      optional: boolean,
      type: string,
      value: string
    }
  ],
  supportsRecurring?: boolean,
  name?: string,
  type?: string
}

export interface PaymentContact {
  email: string;
  first_name: string;
  last_name: string;
  phone_number?: string;
	language: string;
}

export interface VerificationCodeResponse {
  reference: string
  method: string
  status: string
  expiry: Expiry
}

export interface Expiry {
  hours: number
  minutes: number
  seconds: number
}

export interface PaymentLinkParams {
  bookingCode: string
  contact: PaymentContact
  returnUrl: string
}