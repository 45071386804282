import ENV from "../../utils/environments";
import Fetcher from "../../utils/fetcher";
import {AxiosResponse} from "axios";
import {IChannel} from "./channel.types";

class ChannelService {
  static getChannel(): Promise<AxiosResponse<IChannel>> {
    return Fetcher(`${ENV.API_URL}/channels/current/`, 'GET')
  }
}

export default ChannelService;
