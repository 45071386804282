import {DateTime, Duration} from 'luxon';
import { createAction, createAsyncThunk, createReducer, Reducer } from "@reduxjs/toolkit";
import { Methods } from "store/channel/channel.types";
import { SET_METHODS, SET_CONTACT_INFO, SET_SESSION, RESET_SESSION, CLEAR_PAYMENT, SET_RESULT, GET_VERIFICATION, VERIFY_CODE, CLEAR_CODE, SEND_PAYMENT_LINK } from "./paymentConstants";
import PaymentService from "./paymentService";
import { AdyenResult, PaymentContact, PaymentLinkParams, PaymentSessionResponse, PaymentState } from "./paymentTypes";

const initialState: PaymentState = {
  methods: {
    groups: [],
    paymentMethods: [],
    allowedMethods: []
  },
  session: undefined,
  contact: {
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
		language: "",
  },
  result: undefined,
	verification: undefined,
};

export const getPaymentMethods = createAsyncThunk(SET_METHODS, async ({ bookingCode, rules }: { bookingCode: string, rules: Methods }) => {
  const response = await PaymentService.getMethods(bookingCode);
  let allowedMethods = response?.paymentMethods.map((item) => item.type) || [];
  if (rules.whitelist.length) allowedMethods = rules.whitelist;
  if (!rules.whitelist.length && rules.blacklist.length) allowedMethods = allowedMethods.filter(item => !rules.blacklist.includes(item || ""));

  return {
    ...response,
    allowedMethods
  }
})

export const getAdyenSession = createAsyncThunk(SET_SESSION, async ({ bookingCode, contact }: { bookingCode: string, contact: PaymentContact }) =>
  await PaymentService
    .getAdyenSession(bookingCode, window.location.href, contact)
)

export const getVerificationCode = createAsyncThunk(GET_VERIFICATION, async ({ bookingCode, contact, returnUrl }: { bookingCode: string, contact: PaymentContact, returnUrl: string }) =>
  await PaymentService
    .getVerificationCode(bookingCode, contact, returnUrl)
)
export const verifyCode = createAsyncThunk(VERIFY_CODE, async ({ bookingCode, verificationCode, paymentReference, expiryTimestamp }: { bookingCode: string, verificationCode: string, paymentReference: string, expiryTimestamp: string }, {rejectWithValue}) => {
  const now = DateTime.now();
  const timestamp = DateTime.fromISO(expiryTimestamp);
  
  if (now >= timestamp) {
    return rejectWithValue("payment:verification_expired:error");
  }

  try {
    return await PaymentService.verifyCode(bookingCode, verificationCode, paymentReference);
  } catch (error: any) {
    return rejectWithValue("payment:verification:error");
  }
});
export const sendPaymentLink = createAsyncThunk(SEND_PAYMENT_LINK, async (params: PaymentLinkParams, {rejectWithValue}) => {
    try {
      return await PaymentService.sendPaymentLink(params);
    } catch (error: any) {
      return rejectWithValue("payment:link:error");
    }
  }
)
export const setAdyenSession = createAction<Pick<PaymentSessionResponse, 'id'>>(SET_SESSION)
export const resetAdyenSession = createAction(RESET_SESSION)
export const setContactDetails = createAction<PaymentContact>(SET_CONTACT_INFO)
export const setPaymentResult = createAction<AdyenResult>(SET_RESULT)
export const clearPaymentData = createAction(CLEAR_PAYMENT)
export const clearVerificationCode = createAction(CLEAR_CODE)

const paymentReducer: Reducer<PaymentState> = createReducer(initialState, builder => {
  builder.addCase(getPaymentMethods.fulfilled, (state, action) => {
    const filteredMethods = action.payload.allowedMethods.filter((method): method is string => method !== undefined);

    state.methods = {
      ...action.payload,
      allowedMethods: filteredMethods
    };

  })
	builder.addCase(getVerificationCode.fulfilled, (state, action) => {
		const now = DateTime.now();
		const addition = Duration.fromObject(action.payload.expiry);
		const timestamp = now.plus(addition).toISO();
    state.verification = {
			...action.payload,
			expiryTimestamp: timestamp,
			error: ""
		};
  })
	builder.addCase(clearVerificationCode, (state) => {
    state.verification = undefined;
  })

	builder.addCase(verifyCode.fulfilled, (state, action) => {
    state.verification = {
			...action.payload,
			expiryTimestamp: ""
		};
  })
	builder.addCase(verifyCode.rejected, (state, action) => {
		if (state.verification) state.verification.error = action.payload as string;
	});

  builder.addCase(getAdyenSession.fulfilled, (state, action) => {
    state.session = action.payload;
  })
  builder.addCase(resetAdyenSession, (state) => {
    state.session = undefined;
  })
  builder.addCase(setContactDetails, (state, action) => {
    state.contact = action.payload
  })
  builder.addCase(setPaymentResult, (state, action) => {
    state.result = action.payload;
  })
  builder.addCase(clearPaymentData, () => {
    return initialState;
  })
});

export default paymentReducer;