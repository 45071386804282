import InfoCard from "components/InfoCard/InfoCard";
import {AnimatePresence, motion} from "framer-motion";
import React, {useCallback, useEffect, useLayoutEffect, useMemo, useState} from "react";
import "react-datepicker/dist/react-datepicker.css";
import AddressForm, { AddressFields, Community } from "./AddressForm/AddressForm";
import {AddressFormErrors} from "components/AddressForm/AddressForm";
import {ActiveStepProps} from "components/containers/Step/types";
import MapSelector from "components/forms/MapAddressSelector/MapSelector";
import WindowSelector from "components/forms/WindowSelector";
import {LogisticWindow} from "components/forms/WindowSelector/types";
import LuggageIcon from "components/icons/LuggageIcon";
import MinusIcon from "components/icons/MinusIcon";
import PlusIcon from "components/icons/PlusIcon";
import Button from "components/inputs/buttons/Button";
import IntegerInput from "components/inputs/IntegerInput";
import ValidationError from "components/ValidationError";
import {useModal, useOptions, useProperties} from "hooks";
import useGoogleMaps from "hooks/useGoogleMaps";
import useTranslations from "hooks/useTranslations";
import {Coordinates, Location, PickupType} from "store/booking/bookingTypes";
import {BookingState} from "store/booking/types/store";
import {Content, Flow, TermsProperties} from "store/channel/channel.types";
import {AirportDirection} from "store/flight/types/enums";
import {FlightState} from "store/flight/types/store";
import {LocationType} from "store/portal/portalTypes";
import sharedStyles from "styles/Shared.module.scss";
import Utils from "utils/Utils";
import {LocationStepErrors} from "../NeomLocationStep";
import styles from "../NeomLocationStep.module.scss";
import Terms from "layouts/Terms/Terms";
import Modal from "components/modals/Modal";

declare function UpdateCallback(data: { luggage: number, location: Location, window: LogisticWindow }): void;
declare function UpdateCallback(data: { location: Location, window: LogisticWindow }): void;
declare function UpdateCallback(data: { location: Location }): void;
declare function UpdateCallback(data: { luggage: number }): void;
declare function UpdateCallback(data: { luggage?: number, window?: LogisticWindow, location?: Location }): void;

interface Props extends ActiveStepProps {
  loading: boolean;
  onUpdate: typeof UpdateCallback;
  onSubmit: () => void;
  data: {
    error: LocationStepErrors;
    flight: Required<FlightState>;
    booking: BookingState;
    direction: Required<AirportDirection>;
		content: Content;
    flow?: Flow;
		terms?: TermsProperties;
    hubs?: Required<Location>[];
  }
}

interface Checkboxes {
  termsAndConditions: boolean;
  dataProcessing: boolean;
}

const NeomLocationStepActive: React.FC<Props> = (
  {
    onSubmit,
    onUpdate,
    loading,
    data,
  }
) => {
  /**
   * This component is used to collect and validate location and time windows for a given booking.
   *
   * If the information is valid, we trigger the onUpdate callback with the relative information in order
   * to ensure that a booking is created and updated whenever a change is done by the customer.
   *
   * Once the information is validated and the submit button is clicked, the information is passed back to
   * the LocationStep via the onSubmit callback in order to create an Order.
   */

  const {properties} = useProperties();
  const {translation} = useTranslations();
  const {options} = useOptions();
  const {google} = useGoogleMaps();
  const {isShown, toggle} = useModal();

  const getInitialWindow = useCallback(() => {
    if (data.booking.details?.meta?.timeslot === undefined) return

    const date = new Date(data.booking.details.meta.timeslot.formatted).getTime()
    const day = options[date];
    if (day)
      return day.find(slot => slot.label === data.booking.details?.meta?.timeslot?.timeLabel);
  }, [options, data.booking.details?.meta?.timeslot])

  const [luggage, setLuggage] = useState<number>(
    (data.flight.passengers && data.flow === Flow.Airport)
      ? data.flight.passengers.reduce((acc, obj) => acc + obj.luggage || 0, 0)
      : data.booking.details?.assignments[0]?.luggage_count || 1
  );
  const [location, setLocation] = useState<Location | undefined>(data.booking.meeting?.location);
  const [window, setWindow] = useState<LogisticWindow | undefined>(getInitialWindow());
  const [mapCenter, setMapCenter] = useState<Coordinates>();
  const [errors, setErrors] = useState<AddressFormErrors>();
  const [type, setType] = useState<PickupType>(location?.type === LocationType.bagpoint ? PickupType.Hub : PickupType.Address);
	const [isGeocoding, setIsGeocoding] = useState<boolean>(false);

  const city = useMemo(() => data.flight.journey[data.direction].schedule.airport.city, [data.flight, data.direction])

	const luggageLimit = useMemo(() => {
		return data.content.luggageLimit ? data.content.luggageLimit : 18
	}, [data.content.luggageLimit])


  const [checkbox, setCheckbox] = useState<Checkboxes>({
    termsAndConditions: false,
    dataProcessing: data.direction === AirportDirection.Arrival,
  });

  const allCheckboxesSigned = useMemo(() => Utils.object
    .entries(checkbox)
    .filter(([, value],) => !value)
    .length === 0, [checkbox])

  const isReadyForWindowSelection = useMemo(() => {
    /**
     * Allow the customer to select a time window once a location has
     * been selected.
     */

    return !loading && location !== undefined && location.address.number;
  }, [location, loading])

  const onError = useCallback((formErrors: { [key: string]: string }) => {
    /**
     * Merge the form errors under a common form object
     */
    setErrors(formErrors)
  }, [])

  const onWindowSelect = useCallback((window: LogisticWindow) => {
    /**
     * Save the provided window information and update the booking if said booking
     * has already been created.
     */

    setWindow(window);
    const updateLocation = location || data.booking?.meeting?.location;

    if (updateLocation && window) onUpdate({luggage, location: updateLocation, window})
  }, [location, luggage, data, onUpdate])


  const onFormLocationChange = useCallback(async ({form, community}: { form: AddressFields, community: Community }) => {
    /**
     * Save the provided location information and update the booking if said booking
     * has already been created.
     */

    try {
      const {address_type, ...rest} = form;

      if (community && community.coordinates) {
        setIsGeocoding(true);
        const {lat, lng} = community.coordinates;
        Utils.geo.getOSMGeocodeFromCoordinates(lat, lng).then((res: any) => {

          const location = Utils.geo.toLocationOSM(res.data);
          const formatted = {
            ...location,
            address: {
              ...location.address,
              ...rest,
              city: community.name,
              addition: address_type
            }
          };

          setLocation(formatted)
          setWindow(undefined);
          onUpdate({location: formatted})
        });
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsGeocoding(false);
    }
  }, [onUpdate])

	const onMapLocationChange = useCallback((location?: Location) => {
		setLocation(location);
		setWindow(undefined);
	}, [])

  const onLuggageChange = useCallback((luggage: number) => {
    /**
     * Save the luggage count information and update the booking if said booking
     * has already been created.
     */

    setLuggage(luggage);


    const updateLocation = location || data.booking?.meeting?.location;
    if (updateLocation && window) onUpdate({luggage, location: updateLocation, window});
  }, [location, data, window, onUpdate])

  const onCheckboxChange = useCallback((checkbox: keyof Checkboxes) => {
    /**
     * Handle checkbox changes.
     */

    setCheckbox((checkboxes) => {
      return {
        ...checkboxes,
        [checkbox]: !checkboxes[checkbox]
      }
    })
  }, [])

  useLayoutEffect(() => {
    /**
     * Center the map on the city tied to the given airport.
     */

    if (google === undefined) return;

    const airport = data.flight.journey[data.direction].schedule.airport;
    Utils.geo.getGoogleGeocode({
      address: airport.city
    })
      .then((response) => {
        if (response !== undefined) setMapCenter(response.coordinates)
      });
  }, [google, data.flight, data.direction])

  useEffect(() => {
    if (Object.keys(options).length === 0)
      onError({
        pickup: translation.get('validation:location:address_not_serviceable')
      })
  }, [location, translation, options, onError])

  return (
    <>
			<div className={styles.flex}>
				<h2 className={styles.heading}>
					{translation.get(data.flow === Flow.Airport
						? "location:heading:airport"
						: "location:heading:city"
					)}
				</h2>
				<InfoCard className={styles.timeslotInfo} type="info" title="" subtitle={translation.get("location:community:info_box")} />
			</div>

      <div className={styles["map-container"]}>
        {mapCenter &&
          <MapSelector
						formLoading={isGeocoding}
            clickable={false}
						communityDelivery
            onChange={onMapLocationChange}
            location={location}
            allowTypeSelection={data.flow === Flow.Airport && properties.switches.location.display}
            type={type}
            setType={setType}
            center={mapCenter}
            zoom={11}
            hubs={data.hubs || []}
            onError={() => {
            }}
          />
        }
      </div>

      <AnimatePresence>
        {data.error.location && (
          <motion.div
            className={styles.errorMessage}
            initial={{height: '60px', opacity: 0, y: '300px', x: '-50%'}}
            animate={{height: 'auto', opacity: 1, y: '0', x: '-50%'}}
            exit={{height: '60px', opacity: 0, y: '300px', x: '-50%'}}
            transition={{ease: "easeInOut", duration: 0.4, overflow: 'hidden'}}
          >
            <div className={sharedStyles.errorWithTitle}>
              <div>
                {translation.get("error:title")}
              </div>
              <ValidationError error={data.error.location}/>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <div className={styles.wrapper}>
        <AddressForm
          disabled={type === PickupType.Hub}
          className={styles.flexContainer}
          onUpdate={onFormLocationChange}
          location={location}
          country={data.flight.journey[data.direction].schedule.airport.country}
          onError={onError}/>

        <div className={`${styles.flexContainer}`}>
          <WindowSelector
            key={`${Object.keys(options)[0]} ${city}`}
            disabled={!isReadyForWindowSelection}
            onUpdate={onWindowSelect}
            options={options}
            dateLabel={translation.get(data.flow === Flow.Airport
              ? "location:label_date:airport"
              : "location:label_date:city")}
            timezone={data.flight.journey[data.direction].schedule.airport.timezone}
            window={window}
          />
          {errors?.pickup &&
            <InfoCard type="error" title="Error" subtitle={errors.pickup} className={styles.pickupError}/>}

          {(data.flow === Flow.City || !data.flight.passengers) && <div className={styles["luggage-count"]}>
            <div className={styles["luggage-count-input-container"]}>
              <IntegerInput
                label={data.direction === AirportDirection.Departure
                  ? translation.get("location:label_luggage:airport")
                  : translation.get("location:label_luggage:city")}
                icon={LuggageIcon}
                name="luggageCount"
                value={luggage}
                min={1}
                max={luggageLimit}
                disabled={!isReadyForWindowSelection}
                onChange={onLuggageChange}
                addIcon={PlusIcon}
                subtractIcon={MinusIcon}/>
            </div>
          </div>}
        </div>
      </div>
      <div className={styles["submit-button-wrapper"]}>
        <div className={sharedStyles.checkbox}>
          <input
            data-cy="checkbox-terms"
            type="checkbox"
            id="userAgreement"
            onChange={() => {
            }}
            checked={checkbox.termsAndConditions}
            onClick={() => onCheckboxChange('termsAndConditions')}/>
          <label htmlFor="userAgreement">
						{data.terms && Object.keys(data.terms).length !== 0 ? 
						<>
							<p style={{margin: 0}}>
								{translation.get("location:first_policy_checkbox_nolink")} {" "}
								<span onClick={(e) => {
									e.stopPropagation();
									toggle();
								}} className={styles.link}>{translation.get("location:first_policy_checkbox_nolink:terms")}</span>
							</p>
							<Modal 
								isShown={isShown}
								hide={toggle}
								headerText={translation.get('footer:terms:title')}
								modalContent={<Terms terms={data.terms} />}
							/>
						</>
						:
            <p 
							style={{margin: 0}}
              dangerouslySetInnerHTML={{
								__html: translation.get("location:first_policy_checkbox",
								{url: 'https://www.bagpoint.com/terms-conditions/'})
							}}
						/>
						}
          </label>
          {data.direction === AirportDirection.Departure && <>
            <input
              data-cy="checkbox-data"
              type="checkbox"
              id="dataProcessingAgreement"
              checked={checkbox.dataProcessing}
              onChange={() => {
              }}
              onClick={() => onCheckboxChange('dataProcessing')}/>
            <label htmlFor="dataProcessingAgreement">
              {translation.get("location:second_policy_checkbox")}
            </label>
          </>}
        </div>
        <Button
          type="submit"
          data-cy="location-submit"
          id="location-submit"
          text={translation.get("button:next")}
          onClick={onSubmit}
          disabled={!(!!data.booking.details?.assignments && location?.address.number
            && allCheckboxesSigned)}/>
      </div>
    </>
  );
};

export default NeomLocationStepActive;
