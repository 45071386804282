import axios, { AxiosError } from "axios";
import { Interval } from "luxon";
import { getGeocode } from 'use-places-autocomplete';
import { Coordinates, Location, MapAddressResponse } from "../store/booking/bookingTypes";
import { FlowOption, IChannelTranslationsState, IChannelVariables } from "../store/channel/channel.types";
import { LocationType } from "../store/portal/portalTypes";
import { Translation } from "hooks/useProperties/language";

type Entries<T> = {
  [K in keyof T]: [K, T[K]]
}[keyof T][]

class Util {
}

type GoogleGeocode = {
  addressInfo: google.maps.GeocoderResult,
  coordinates: Coordinates
}


export interface OSMLocation {
  place_id: number;
  licence: string;
  osm_type: string;
  osm_id: number;
  lat: string;
  lon: string;
  display_name: string;
  address: Address;
  boundingbox: string[];
}
export interface Address {
  tourism: string;
  house_number: string;
  road: string;
  residential: string;
  suburb: string;
  city: string;
  municipality: string;
  state: string;
  "ISO3166-2-lvl4": string;
  country: string;
  postcode: string;
  country_code: string;
  town?: string;
  village?: string;
}

class GeoUtil extends Util {
  private static isCoordinate(object: any): object is Coordinates {
    if (typeof object !== "object") return false;
    return 'latitude' in object && 'longitude' in object
  }

  private static async getGeocodeFromCoordinates(coordinates: Coordinates): Promise<GoogleGeocode> {
    try {
      const results = await getGeocode({
        location: {
          lat: coordinates.latitude,
          lng: coordinates.longitude
        }
      })

      const addressInfo = results[0];

      return { addressInfo, coordinates }
    } catch (error) {
      throw error;
    }
  }

  static async getOSMGeocodeFromCoordinates(lat: number, lng: number) {
    try {
      return await axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json&limit=50`);
    } catch (error) {
      throw error;
    }
  }

  static async getOSMGeocode(address: {
    street: string, number: string, city: string, addition: string
  }) {
    const { street, number, city, addition } = address;
    try {
      const { data } = await axios.get(`https://nominatim.openstreetmap.org/search?street=${addition ? `${number}-${addition}` : number} ${street}&city=${city}&addressdetails=1&format=json&limit=50`);
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async getGoogleGeocode(
    { address, countryRestrictions, placeId }: {
      address?: string | Coordinates,
      countryRestrictions?: string,
      placeId?: string
    }
  ): Promise<GoogleGeocode> {
    /**
     * Retrieve an address info along with its latitude and longitude from google's geocode API.
     */

    if (this.isCoordinate(address)) return this.getGeocodeFromCoordinates(address);

    let query = typeof address === "object"
      ? Object.values(address).filter(value => !!value).join(', ')
      : address;

    try {
      const result = await getGeocode({
        ...placeId && { placeId },
        ...query && { address: query },
        ...countryRestrictions && { componentRestrictions: { country: countryRestrictions } }
      });

      const addressInfo = result[0];
      const lat: number = result[0].geometry.location.lat();
      const lng: number = result[0].geometry.location.lng();

      return {
        addressInfo,
        coordinates: { latitude: lat, longitude: lng }
      }
    } catch (error) {
      throw error;
    }
  }

  static toLocationOSM(osmLocation: OSMLocation): Location {
    const city = osmLocation.address.city
      ?? osmLocation.address.town
      ?? osmLocation.address.village
      ?? osmLocation.address.municipality
      ?? osmLocation.address.state;

    return {
      name: osmLocation.display_name,
      description: "",
      type: LocationType.private,
      coordinates: {
        latitude: parseFloat(osmLocation.lat),
        longitude: parseFloat(osmLocation.lon)
      },
      address: {
        street: osmLocation.address.road,
        number: osmLocation.address.house_number,
        addition: '',
        postal_code: osmLocation.address.postcode,
        city,
        region: osmLocation.address.state,
        country: osmLocation.address.country,
        coordinates: {
          latitude: parseFloat(osmLocation.lat),
          longitude: parseFloat(osmLocation.lon)
        },
      }
    };
  }

  static toLocation(addressInfo: google.maps.GeocoderResult, coordinates: Coordinates): Location {
    /**
     * Converts a google map GeocoderResult into a format which can be consumed by our API.
     */
    let address: MapAddressResponse = {
      administrative_area_level_1: '',
      administrative_area_level_2: '',
      country: '',
      locality: '',
      name: '',
      political: '',
      postal_code: '',
      route: '',
      street_number: '',
    };

    addressInfo.address_components.forEach((component: any) => {
      const key: string = component.types[0];
      address[key] = component.types[0] === 'country' ? component.short_name : component.long_name;
      address.name = addressInfo.formatted_address;
    });

    return {
      name: address.name,
      description: "",
      type: LocationType.private,
      coordinates: coordinates,
      address: {
        street: address.route,
        number: address.street_number,
        addition: '',
        postal_code: address.postal_code,
        city: address.locality,
        region: address.administrative_area_level_1,
        country: address.country,
        coordinates: coordinates,
      }
    };
  }

  static getCountryNameFromISO(country: string, locale: string) {
    /**
     * Fix location country name
     */
    if (country.length > 2) return country;

    return new Intl
      .DisplayNames([locale], { type: 'region' })
      .of(country.toUpperCase()) || country
  }
}

class LayoutUtil extends Util {
  static setChannelVariables(variables: IChannelVariables): void {
    /**
     * Changes the properties which are used by our css to define the colors used in our channels.
     */
    const root = document.querySelector<HTMLInputElement>(':root');
    if (!root) throw Error("Could not retrieve root selector");

    Object.entries(variables).forEach(([key, value]) => {
      let variable = key
      .replaceAll('[', '__')
      .replaceAll(']:', '-')
      .replaceAll(']::', '--')
      .replaceAll(':', '-')
      .replaceAll('::', '--')

      variable = variable.replaceAll('---', '--');

      root.style.setProperty(`--${variable}`, value)

    })
  }

  static scrollToTop(): void {
    /**
     * Scrolls the page back to the top.
     */
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  static scrollTo(element: HTMLDivElement, options?: { offset: number, behavior: ScrollBehavior }) {
    /**
     * Scrolls the viewport for the desired element
     */
    if (element === null) return;

    window.scrollTo({
      'top': element.getBoundingClientRect().top + window.scrollY - (options?.offset || 94),
      'behavior': options?.behavior || "smooth"
    })
  }

  static isInViewport(element: Element): boolean {
    /**
     * Checks if a given element is currently within the visible area of the page.
     */
    const rect = element.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
}

class ObjectUtil extends Util {
  static entries<T extends Object>(obj: T): Entries<Required<T>> {
    /**
     * Correctly typed version of Object.entries
     */
    return Object.entries(obj) as any;
  }

  static checkForFalsyProperties(fields: Object, all = false) {
    if (all) {
      return Object.values(fields).every(field => [undefined, ""].includes(field))
    }
    return Object.values(fields).some(field => [undefined, ""].includes(field))
  }

  static isObject(item: any) {
    return (item && typeof item === 'object' && !Array.isArray(item));
  }

  static mergeDeep<T>(source: Record<string, any>, target: Record<string, any>, overwriteKeys?: string[]): T {
    /**
     * Merges logistics properties except the keys present in overwriteKeys array.
     * Those are overwritten.
     */

    const output = Object.assign({}, target);

    if (this.isObject(target) && this.isObject(source)) {
      Object.keys(source).forEach((key) => {
        if (this.isObject(source[key])) {
          if (!(key in target) || (overwriteKeys && overwriteKeys.includes(key)))
            Object.assign(output, { [key]: source[key] });
          else
            output[key] = this.mergeDeep(source[key], target[key], overwriteKeys);
        } else {
          Object.assign(output, { [key]: source[key] });
        }
      });
			if (source['header::background'] && !output['header:inverted::background']) {
				output['header:inverted::background'] = source['header::background'];
			}

			if (source.images && source.images.desktopLogo && !output.images.invertedLogo) {
				output.images.invertedLogo = source.images.desktopLogo;
			}
    }

    return output as T;
  }

  static hasDuplicates = (arr: string[]) => {
    const filteredArr = arr.filter(Boolean);
    return new Set(filteredArr).size !== filteredArr.length;
  }
}

class NetworkUtil extends Util {
  static isAxiosError(object: any): object is AxiosError {
    return 'isAxiosError' in object
  }
}

class StringUtil extends Util {
  static formatFlightNumber(flightNumber: string): string {
    /**
     * Return a formatted flight number.
     *
     * A formatted flight number should always consist of 6 characters with the first two characters
     * indicating the airline and the last 4 consisting of a `0` padded flight number.
     */
    let updatedFlightNumber = flightNumber;
    if (updatedFlightNumber.length < 6) {
      const missingZeroCount = 6 - (flightNumber.slice(0, 2).length + flightNumber.slice(2).length);
      updatedFlightNumber = flightNumber.slice(0, 2) + '0'.repeat(missingZeroCount) + flightNumber.slice(2);
    }
    return updatedFlightNumber;
  }

  static capitalize(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

	static parseText (text: string, translation: Translation) {
		/**
     * Used to parse translations inside {{ }}
		 * return the translated text along with any other plain text
     */

		const translationKeyPattern = /{{\s*([\w.]+)\s*}}/g;
		return text.replace(translationKeyPattern, (_, key: keyof IChannelTranslationsState) => {
			return translation.get(key)
		});
	};
}

class DateUtil extends ObjectUtil {
  static intersection(a: Interval[]): Interval | null;
  static intersection(a: Interval, b: Interval): Interval;
  static intersection(a: Interval | Interval[], b?: Interval): Interval | null {
    /**
     * Useful when trying to reduce a list of DateTime ranges to the overlap of all the
     * values.
     */

    if (Array.isArray(a)) {
      try {
        return a.reduce(DateUtil.intersection)
      } catch (e) {
        return null;
      }
    }
    if (b === undefined) throw Error("b must be an interval.");

    const intersect = a.intersection(b);
    if (intersect === null) throw TypeError("Invalid type");

    return intersect;
  }

  /**
   * For a given range of intervals a; return a new list of intervals which does not overlap with b.
   */
  static difference(a: Interval[], b: Interval[]): Interval[];
  static difference(a: Interval | Interval[], b: Interval[]): Interval[] {
    if (Array.isArray(a)) a = a.reduce(DateUtil.intersection)

    return a.difference(...b)
  }

  static * days(interval: Interval) {
    /**
     * Returns a generator used to iterate over an array of intervals seperated by date while maintaining the same start
     * and end datetime as the provided interval.
     */

    if (!interval.start || !interval.end) return;

    let cursor = interval.start.startOf("day");
    while (cursor < interval.end) {
      yield Interval.fromDateTimes(
        cursor < interval.start ? interval.start : cursor.startOf('day'),
        cursor.endOf('day') > interval.end ? interval.end : cursor.endOf('day')
      );
      cursor = cursor.plus({ days: 1 });
    }
  }

  static parse(date: string): string {
    /**
     * This is currently being abused and should be changed to use luxon.
     */
    const parsed = Date.parse(date);
    if (!isNaN(parsed)) {
      return date;
    }
    return date.slice(0, 29) + ":" + date.slice(29, 31);
  }
}

export const setFlowOptions = (flows: FlowOption, name: string): FlowOption => {
  /**
   * Enrich flow definitions with a title and an icon.
   */
  let flowKeys = Object.keys(flows);

  return flowKeys.reduce((cur, key) => {
		const flow = name.toLowerCase().includes("neom") ? "community" : key
    return Object.assign(cur, {
      [key]: {
        components: flows[key],
        title: `flow:title:${flow}`,
        icon: `./static/media/${flow}.svg`
      }
    })
  }, {});
}



let utils = {
  object: ObjectUtil,
  date: DateUtil,
  layout: LayoutUtil,
  geo: GeoUtil,
  string: StringUtil,
  network: NetworkUtil,
}

export default utils;